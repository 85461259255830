import './ServiceAccessPoints.style.scss'

import {useCallback} from 'react'
import {TAccessScheduleFields} from '../../../components/AccessSchedule/AccessSchedule'
import {TAccessPointsValue} from '../useAccessPoints'
import usePinTransactions from '../../../hooks/data/usePinTransactions'

import {scheduleToString} from '../../../functions'
import useServicePoints from './useServiceAccessPoints'
import Button from '../../../components/Button'
import InputToggle from '../../../components/InputToggle'
import {pinToString} from '../../../functions/lock.functions'
import useUserPersonId from '../../../hooks/useUserPersonId'
import {client} from '../../../data/graphql'
import {GET_PERSON_ACCESSES_BY_ID} from '../../../data/graphql/queries/people'
import {isCommonAreaBuilding} from '../../../functions/devices.function'
import useOtherPersonProfilesAccess from './useOtherPersonProfilesAccess'
import {PersonType} from '../../../data/graphql/queries/enums'

export type Props = {
  noSchedule?: boolean
  value: TAccessPointsValue<TAccessScheduleFields>
  serviceData: ReturnType<typeof useServicePoints>
  personId: number
  profileId: number
}

const useServiceUnitRow = ({
  profileId,
  personId,
  noSchedule,
  value,
  serviceData,
}: Props) => {
  const otherProfileAccesses = useOtherPersonProfilesAccess({personId, profileId})

  const initiatorPersonId = useUserPersonId()
  const {unitTransactionsMap, retryTransaction, cancelTransaction, actionLoading} =
    usePinTransactions('', {
      initiatorPersonId,
      targetPersonId: personId,
    })
  const {building, property, toggleUnit, openScheduleModal} = serviceData
  const isCommonArea = isCommonAreaBuilding(building)
  const propertyAccesses = property ? value[property?.propertyId] : null
  const buildingAccesses = building
    ? propertyAccesses?.buildings?.[building?.buildingId]
    : null

  const getScheduleString = useCallback(
    (schedule?: TAccessScheduleFields | null) => {
      const label = schedule && noSchedule ? 'Always' : scheduleToString(schedule)

      if (schedule?.accessType === 'pin') {
        return label + ' (pin only)'
      }

      return label
    },
    [noSchedule],
  )

  const isUnitSelected = useCallback(
    (unitId: string) => {
      const propertyId = property?.propertyId
      const buildingId = building?.buildingId

      if (propertyId && buildingId && unitId) {
        return !!Object.hasOwn(buildingAccesses?.units || {}, unitId)
      }

      return false
    },
    [property?.propertyId, building?.buildingId, buildingAccesses?.units],
  )

  const isToggleDisabled = useCallback(
    (unitId: string): boolean => {
      return (
        isCommonArea ||
        unitTransactionsMap?.[unitId]?.status === 'Pending' ||
        !!otherProfileAccesses.data.units[unitId]
      )
    },
    [isCommonArea, unitTransactionsMap, otherProfileAccesses.data],
  )

  const getUnitScheduleLabel = useCallback(
    (unitId: string) => {
      const transaction = unitTransactionsMap[unitId]
      const isChecked = isUnitSelected(unitId)
      const unitAccess = buildingAccesses?.units?.[unitId]
      const access =
        isChecked || transaction?.status === 'Pending'
          ? unitAccess || buildingAccesses?.data || propertyAccesses?.data
          : null

      const scheduleLabel = getScheduleString(access)

      if (scheduleLabel) {
        const accessValue = getScheduleString(access)
        const isInherited = !!scheduleLabel && !!accessValue && !unitAccess

        return `${scheduleLabel} ${isInherited ? ' (inherited)' : ''}`
      } else if (access) {
        return 'No available devices'
      }

      return ''
    },
    [
      isCommonArea,
      buildingAccesses?.data,
      buildingAccesses?.units,
      getScheduleString,
      propertyAccesses?.data,
      isUnitSelected,
    ],
  )

  const renderTransactionStatus = useCallback(
    (unitId: string) => {
      const transaction = unitTransactionsMap[unitId]

      if (!transaction) {
        return null
      }

      const {status, pinOperationType} = transaction
      const statusLabelMap = {
        Failed: 'failed',
        Pending: 'pending',
      } as const

      const label = `Pin ${pinOperationType} ${statusLabelMap[status]}`

      if (label) {
        return <span className={`${transaction.status}-label`}>{label}</span>
      }

      return null
    },
    [unitTransactionsMap],
  )

  const renderActiveSchedule = useCallback(
    (unitId: string) => {
      if (!isUnitSelected(unitId)) {
        return null
      }

      const otherAccessPersonType = otherProfileAccesses.data.units[unitId]?.personTypeId
      const transaction = unitTransactionsMap[unitId]
      const scheduleLabel = getUnitScheduleLabel(unitId)

      if (otherAccessPersonType) {
        const personTypes = {
          [PersonType.EMPLOYEE]: 'employee',
          [PersonType.RESIDENT]: 'resident',
          [PersonType.GUEST]: 'guest',
          [PersonType.VENDOR]: 'vendor',
        }
        const personType = personTypes[otherAccessPersonType] || 'another'

        return (
          <span className='schedule-link disabled'>{`The user already has this access under ${personType} role.`}</span>
        )
      }

      if (isCommonArea || transaction) {
        return <span className='schedule-link disabled'>{scheduleLabel}</span>
      }

      return (
        <span className='schedule-link' onClick={() => openScheduleModal({unitId})}>
          {scheduleLabel}
        </span>
      )
    },
    [
      isCommonArea,
      isUnitSelected,
      unitTransactionsMap,
      buildingAccesses?.units,
      buildingAccesses?.data,
      propertyAccesses?.data,
      getUnitScheduleLabel,
      openScheduleModal,
    ],
  )

  const renderPendingSchedule = useCallback(
    (unitId: string) => {
      const transaction = unitTransactionsMap[unitId]
      const className = `${transaction?.status}-label`

      if (transaction?.pinOperationType === 'delete') {
        return <span className={className}>None</span>
      } else if (transaction?.pinOperationType === 'load') {
        const schedule = pinToString({
          accessType: transaction?.pinAccessType,
          accessRecurrence: transaction?.pinAccessRecurrence || '',
          accessTimes: transaction?.pinAccessTimes || '',
        })

        return <span className={className}>{schedule}</span>
      }

      return null
    },
    [unitTransactionsMap],
  )

  const renderActionCell = useCallback(
    (unitId: string) => {
      const transaction = unitTransactionsMap[unitId]

      if (transaction?.status === 'Failed') {
        return (
          <div className={'unit-actions'}>
            <Button
              size={'sm'}
              disabled={actionLoading}
              theme={'outline'}
              onClick={() => {
                retryTransaction(transaction)
              }}
            >
              {actionLoading ? 'Loading...' : 'Retry'}
            </Button>
            <Button
              size={'sm'}
              theme={'danger'}
              disabled={actionLoading}
              onClick={async () => {
                await cancelTransaction(+transaction.asyncTransactionId)
                client.refetchQueries({
                  include: [GET_PERSON_ACCESSES_BY_ID],
                })
              }}
            >
              {actionLoading ? 'Loading...' : 'Cancel'}
            </Button>
          </div>
        )
      }

      return (
        <InputToggle
          disabled={isToggleDisabled(unitId)}
          isChecked={isUnitSelected(unitId)}
          onValueChange={value => {
            toggleUnit(value, unitId)
          }}
        />
      )
    },
    [
      actionLoading,
      unitTransactionsMap,
      isToggleDisabled,
      isUnitSelected,
      cancelTransaction,
      toggleUnit,
      retryTransaction,
    ],
  )

  const getRowClassname = useCallback(
    (unitId: string) => {
      const transaction = unitTransactionsMap[unitId]

      if (transaction?.status === 'Failed') {
        return 'failed-unit-row'
      } else if (transaction?.status === 'Pending') {
        return 'pending-unit-row'
      }

      return ''
    },
    [unitTransactionsMap],
  )

  const renderUnitRow = useCallback(
    (unitId: string) => ({
      rowClassname: getRowClassname(unitId),
      schedule: renderActiveSchedule(unitId),
      status: renderTransactionStatus(unitId),
      pendingSchedule: renderPendingSchedule(unitId),
      isEnabled: renderActionCell(unitId),
    }),
    [
      getRowClassname,
      renderActionCell,
      renderActiveSchedule,
      renderPendingSchedule,
      renderTransactionStatus,
    ],
  )

  return {render: renderUnitRow, getScheduleString}
}

export default useServiceUnitRow
