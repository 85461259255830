import {useMemo} from 'react'
import useUserEmployee from './useUserEmployee'
import {DepartmentCode} from '../data/graphql/types'
import useCurrentProfile from './useCurrentProfile'
import {PersonType} from '../data/graphql/queries/enums'

const useRestrictedFeatures = () => {
  const profile = useCurrentProfile()
  const employee = useUserEmployee()
  const isDevAdmin = profile.personTypeId === PersonType.DEV_ADMIN
  const departamentCode = employee.data?.departmentCode

  const features = useMemo(() => {
    return {
      masterPin: isDevAdmin || departamentCode === DepartmentCode.VPO,
    }
  }, [isDevAdmin, departamentCode])

  return {
    features,
    loading: employee.loading,
  }
}

export default useRestrictedFeatures
